import React from "react"
import { Link , graphql} from "gatsby"
import * as PropTypes from "prop-types"

import { Breadcrum, SetTokens, SEO } from "../components/index"
import * as styles from './index.module.scss'
import { baseSetType, setTokenType } from '../types'
import { numberWithCommas } from '../utils/formatNumber'


class HomePage extends React.PureComponent {
  breadcrumPages = [
    {
      text: 'Homepage',
      active: true,
      to: '/'
    }
  ];
  render () {
    const { latest, total, typeTotal, totalSets } = this.props.data;
    const { node } = latest.edges[0];
    return (
      <>
        <SEO
          title="Magic: the Gathering token cards"
          description={`MTG.onl Tokens is the ultimate resource for discovering Magic: the Gathering token cards. Explore all of the ${total.totalCount} officially printed tokens, compare artwork, search the ${typeTotal.totalCount} different token types and find the tokens you need for your next MTG deck.`}
          pageUrl="/"
        />
        <Breadcrum pages={this.breadcrumPages} />
        <article className={styles.section}>
          <h1>MTG.onl Tokens</h1>
          <h3>The ultimate resource for discovering Magic: the Gathering token cards.</h3>
          <p>Tokens have been a part of the trading card game Magic: the Gathering (MTG) for over twenty years. MTG.onl is a fan site and database listing every MTG token and providing original and interesting posts all about token cards.</p>
          <p>As of {node.releaseDate}, <span className="big">{numberWithCommas(total.totalCount)}</span> token cards have been officially printed by Wizards of the Coast, spanning <span className="big">{totalSets.totalCount}</span> different sets. View <Link to="/tokens/">Tokens by set</Link> to discover them all. Compare the artworks and find the perfect token card for your next MTG deck.</p>
          <p><span className="big">{typeTotal.totalCount}</span> different types of token can be created in MTG, our <Link to="/token-list/">A-Z Token list</Link> lists all the tokens you will need for anything, ever!</p>
        </article>
        <section className={styles.section}>
          <h1>Latest release:</h1>
          <SetTokens set={node} />
          <Link to="/tokens/" className={styles.moreBtn}>View all sets &rarr;</Link>
        </section>
      </>
    )
  }
}

HomePage.propTypes = {
  data: PropTypes.shape({
    typeTotal: PropTypes.shape({
      totalCount: PropTypes.number.isRequired
    }).isRequired,
    total: PropTypes.shape({
      totalCount: PropTypes.number.isRequired
    }).isRequired,
    totalSets: PropTypes.shape({
      totalCount: PropTypes.number.isRequired
    }).isRequired,
    latest: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          ...baseSetType,
          tokens: PropTypes.arrayOf(PropTypes.shape({
            ...setTokenType
          }))
        }).isRequired,
      })).isRequired,
    }).isRequired,
  }).isRequired
}

export const homePageQuery = graphql`
  query HomePageQuery {
    typeTotal: allTypesJson {
      totalCount
    }
    total: allTokensJson(filter: {
      proxy: {
        ne: true
      }
    }) {
      totalCount
    }
    totalSets: allSetsJson {
      totalCount
    }
    latest: allSetsJson(
      sort: {fields: [releaseDate], order: DESC}
      limit: 1
    ) {
      edges {
        node {
          id
          name
          releaseDate(formatString: "MMMM Do, YYYY")
          tokens {
            id
            number
            promo
            foil
            imgSrc {
              childImageSharp {
                fluid(maxWidth: 175) {
                  base64
                  src
                  srcSet
                  aspectRatio
                  sizes
                }
              }
            }
            token {
              name
              id
            }
          }
        }
      }
    }
  }
`

export default HomePage
